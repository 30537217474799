'use client';
import cn from 'classnames';
import { useLocale } from 'next-intl';
import AspectRatio from 'react-aspect-ratio';

import { Link } from '@lib/navigation';

import 'react-aspect-ratio/aspect-ratio.css';
import { LOCALES } from 'locales/config';
import PremiumBadge from '@components/Article/atoms/badges/PremiumBadge';
import VideoIcon from 'public/static/post/video.svg';
import PlaybackButton from 'public/static/svg/playback.svg';
import { PostType } from 'types/post';

type Props = {
  imgUrl?: string | null;
  className?: string;
  imgClass?: string;
  ratio?: '4/3' | '16/9' | '7/5' | '3/4' | '4/7' | '16/10';
  width?: string;
  isDarken?: boolean;
  isZoomable?: boolean;
  postType: PostType;
  alt?: string;
  url?: string | null;
  urlLocale?: (typeof LOCALES)[number];
  isLive?: boolean;
  isInterview?: boolean;
  isPlayback?: boolean;
  isPodcast?: boolean;
  isPremium?: boolean;
  compactBadge?: boolean;
};

const CoverPhoto = ({
  imgUrl = 'https://cdn-trans.info/uploads/2017/09/86d06cfc167f8e3f01657fde7eb-1024x684.jpg',
  ratio = '4/3',
  width = '100%',
  className,
  imgClass = '',
  isDarken = true,
  isZoomable = false,
  postType,
  alt,
  urlLocale,
  isLive,
  isInterview,
  isPlayback,
  isPodcast,
  isPremium,
  compactBadge,
  url = null,
}: Props) => {
  const lang = useLocale();
  const wrapper = cn('cover-wrapper', className, postType, {
    pointer: url !== null,
    live: isLive,
    interview: isInterview,
    podcast: isPodcast,
    playback: isPlayback,
    premium: isPremium,
    'compact-badge': compactBadge,
  });
  const wrapperClass = cn('cover-img-wrapper', {
    'img-zoom': isZoomable,
  });
  const imageClass = cn('cover-img', imgClass, {
    zoomable: isZoomable,
    darken: isDarken,
  });
  const playClass = cn('video-play', {
    pl: lang === 'pl',
  });

  const imageUrl = imgUrl ?? 'https://cdn-trans.info/uploads/2017/09/86d06cfc167f8e3f01657fde7eb-1024x684.jpg';

  const ratioAspect: string | undefined = ratio;

  const image = (
    <div className={wrapper}>
      <AspectRatio ratio={ratioAspect || 1} style={{ width }} className={wrapperClass}>
        <img
          loading="lazy"
          src={imageUrl}
          className={cn(imageClass, 'transition-opacity duration-500')}
          alt={alt ?? ''}
        />
      </AspectRatio>
      {!isPlayback && (
        <div className={cn(playClass, 'transform transition-all duration-500 ease-in-out hover:scale-110')}>
          <VideoIcon className="play-icon" />
        </div>
      )}
      <div className="live-label">
        <div className="live-dot" />
        LIVE
      </div>
      <div className="interview-label">WYWIAD</div>
      <div className="podcast-label">PODCAST</div>
      <div className="premium-label">
        <PremiumBadge full="always" />
      </div>
      <div className="playback-svg">
        <PlaybackButton />
      </div>
    </div>
  );

  return url === null ? (
    image
  ) : (
    <Link href={url} locale={urlLocale}>
      {image}
    </Link>
  );
};

export default CoverPhoto;
